<div class="edit-container">
    <div class="title-close-btn">
        <h1># Entwicklerteam</h1>
        <img (click)="closeEditWindowChannel()" src="assets/img/close.png">
    </div>
    <div class="position">
            <div id="channelBorder" class="channel-name border">
                <div id="editAndChannelName" class="position-edit-channel-name">
                    <h2 id="channelName">Channel-Name</h2>
                    <h2 id="edit" (click)="editChannelName()" class="edit">Bearbeiten</h2>
                    <h2 id="save" class="save d-none">Speichern</h2>
                </div>

                <div id="nameOfChannel" class="name-of-channel">
                    <h2>#</h2>
                    <h3>Entwicklerteam</h3>
                </div>

                <input id="inputChannelName" class="d-none" placeholder="# Entwicklerteam">

            </div>
       

        <div class="description-channel">
            <div class="container-description">
                <div class="position-edit-description">
                    <h2>Beschreibung</h2>
                    <h2 class="edit">Bearbeiten</h2>
                    <h2 class="d-none">Speichern</h2>
                </div>
                <span class="text">text</span>
            </div>

            <div class="position-line">
                <div class="line"></div>
            </div>

            <div class="creat-from-container">
                <h2>Erstellt von</h2>
                <h1>Noah Braun</h1>
            </div>
        </div>
    </div>
    <img class="left-channel-btn" (click)="leftEditWindowChannel()" src="assets/img/channel_verlassen_btn.png">
</div>