<div class="workspace-menu nunito">
    <div class="workspace-menu-header">
        <img src="assets/img/logo_workspace.png" alt="">
        <h1>Code Learning</h1>
        <div class="div-edit-square">
            <img class="edit-square" src="assets/img/edit_square.png" alt="">
        </div>
    </div>
    <div>
        <div class="workspace-channels-header">
            <div class="workspace-channels-header-left" (click)="toggleChannels()">
                <div class="div-drop-down-img">
                    <img id="drop-down-channels" class="drop-down-img" src="assets/img/arrow_drop_down.png">
                </div>
                <img class="logo-channels" src="assets/img/logo_channels.png">
                <span>Channels</span>
            </div>
            <div class="div-add">
                <img class="add-img" src="assets/img/add.png">
            </div>
        </div>
        <div class="pl-40" id="content-channels">
            <div class="channels">
                <img src="assets/img/tag.png">
                <span>Entwicklerteam</span>
            </div>
            <div class="add-channel">
                <img src="assets/img/add_circle.png">
                <span>Channel hinzufügen</span>
            </div>
        </div>
    </div>
    <div class="workspace-contacts">
        <div class="workspace-contacts-header">
            <div class="workspace-contacts-header-left" (click)="toggleContacts()">
                <div class="div-drop-down-img">
                    <img id="drop-down-contacts" class="drop-down-img" src="assets/img/arrow_drop_down.png">
                </div>
                <img class="logo-contacts" src="assets/img/account_circle.png">
                <span>Direktnachrichten</span>
            </div>   
        </div>
        <div class="pl-40" id="content-contacts">
            <div class="contacts">
                <img src="assets/img/avatar3.png">
                <span>Frederik Beck</span>
            </div>
            <div class="contacts">
                <img src="assets/img/avatar2.png">
                <span>Sofia Müller</span>
            </div>
        </div>
    </div>
    
</div>