<div class="threat-container">
     <!--it´s the window for chat messages-->
    <div class="thread-chat-content">
         <!--Navbar  -->
        <div class="chat-navbar">
            <div class="position-title-thema" >
                <h2>Thread</h2>
                <span># Entwicklerteam</span>
            </div>
            <img src="assets/img/close.png">
        </div>
    </div>
     <!--it´s the window for texting your message -->
    <div class="chat-tastatur">

    </div>

</div>