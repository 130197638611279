<div id="create-account-container">
  <app-header></app-header>
  <mat-card>
    <button routerLink="/login" class="arrow-back-btn">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <mat-card-header>

      <h2>Konto erstellen</h2>
      <h3>
        Mit deinem Namen und deiner E-Mail-Adresse hast du dein neues
        DABubble-Konto.
      </h3>
    </mat-card-header>
    <mat-card-content>
      <form
        id="login-card-container"
        #myForm="ngForm"
        (ngSubmit)="onSubmit(myForm)"
      >
        <div id="inputs-container">
          <div class="inputFields-container name-input-container">
            <mat-icon fontIcon="mail_outline"></mat-icon>
            <input
              type="text"
              id="name"
              class="login-inputs"
              name="name"
              placeholder="Vor und Nachname"
              ngModel
              required
              email
            />
          </div>
          <div class="inputFields-container email-input-container">
            <mat-icon fontIcon="mail_outline"></mat-icon>
            <input
              type="email"
              id="email"
              class="login-inputs"
              name="email"
              placeholder="beispielname@email.com"
              ngModel
              required
              email
            />
          </div>

          <div class="inputFields-container password-input-container">
            <mat-icon fontIcon="lock_outline"></mat-icon>
            <input
              type="password"
              id="password"
              class="login-inputs"
              placeholder="Passwort"
              name="password"
              ngModel
              required
              minlength="8"
            />
          </div>
          <mat-checkbox [color]="task.color" class="example-margin"
            ><span class="fs-16">Ich stimme der</span
            ><a class="purple-link" routerLink="/privacy-policy"
              >Datenschutzerklärung</a
            ><span class="fs-16">zu.</span>
          </mat-checkbox>
        </div>
        <ng-container *ngIf="isDisabled; else elseTemplate">
          <button class="main-btn disabled-btn">Anmelden</button>
        </ng-container>
        <ng-template #elseTemplate>
          <button routerLink="/create-account/avatar" class="main-btn purple1-btn">Anmelden</button>
        </ng-template>
      </form>
      <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
    </mat-card-content>
  </mat-card>
  <app-footer></app-footer>
</div>
