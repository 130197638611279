<div class="message-field-container">
    <div class="input-container">
        <textarea [(ngModel)]="textAreaInput" placeholder="Nachricht an #Channelname"></textarea>
    </div>
    <div class="button-container">
        <div class="first-icon-container">
            <mat-icon class="icon">add</mat-icon>
            <div class="vector"></div>
            <mat-icon (click)="CloseEmojiService.isEmojiPickerVisible = !CloseEmojiService.isEmojiPickerVisible;"
                class="icon">sentiment_satisfied</mat-icon>
            <mat-icon class="icon">alternate_email</mat-icon>
        </div>
        <div>
            <mat-icon class="icon-send">send</mat-icon>
        </div>
    </div>
    <emoji-mart (click) = "closeEmojiField()" class="emoji-mart" *ngIf="CloseEmojiService.isEmojiPickerVisible" (emojiSelect)="addEmoji($event)"
        title="Choose your emoji"></emoji-mart>
</div>