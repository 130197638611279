<div class="header-container">
    <app-logo></app-logo>
    <div class="input-container">
        <input placeholder="'Hauptname' dursuchen" type="text">
        <mat-icon class="search-icon">search</mat-icon>
    </div>
    <div (click) = "openDialog()" class="user-container">
        <span>{{firestore.currentUser.firstName}} {{firestore.currentUser.lastName}}</span>
        <img src="assets/img/avatar3.png" alt="avatar">
        <mat-icon>expand_more</mat-icon>
    </div>
</div>
