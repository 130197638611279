import { Component } from '@angular/core';

@Component({
  selector: 'app-thread-window',
  standalone: true,
  imports: [],
  templateUrl: './thread-window.component.html',
  styleUrl: './thread-window.component.scss'
})
export class ThreadWindowComponent {

}
