
    <div (click)="closeEmojiField()" class="main-board">
        <app-board-header></app-board-header>
        <div class="position-chat">
            <app-workspace-menu-togglebar></app-workspace-menu-togglebar>
            <app-workspace-menu id="app-workspace-menu"></app-workspace-menu>
            <app-channel-chat-window></app-channel-chat-window>
            <app-thread-window></app-thread-window>
        </div>
</div>
   